import {
  chickenstareter_chickenmajestic,
  chickenstarter_chicken_lolliop,
  chickenstarter_chicken8to8,
  chickenstarter_chicken555,
  chickenstarters_chickenoctopus,
  chickenstarters__kfcchicken,
  chickenstarters_apollochicken,
  chickenstarters_chicken65,
  chickenstarters_chickenmanchuria,
  chickenstarters_chillichicken,
  chickenstarters_crispyfrychicken,
  chickenstarters_housla_chicken,
  chickenstarters_pepperchicken,
  Chichencurries_spl_thalka_chciken,
  chicken_curries_butter_chicken,
  chicken_curries_chicken_lajawab,
  chickencurries_chicken_tandi,
  chicken_curries_mughlai_chicken,
  chickencurries_afgani_chicken,
  chickencurries_andhrachicken,
  chickencurries_chicken_chutpata,
  chickencurries_chicken_curry,
  chickencurries_chicken_kholapuri,
  chickencurries_chicken_tikka_masala,
  chickencurries_dum_ka_chicken,
  chickencurries_ginger_chicken,
  chickencurries_kadai_chicken,
  chickencurries_methi_chicken,
  chickencurries_punjabi_chicken,
  chickencurries_spl_begum_chicken,
  chickencurries_spl_murg_musalam,
  chickencurries_tandoori_chickenmasala,
  chickencurries_telanaganachicken,
  vegcurrys_dal_chatpata,
  vegcurrys_dal_fry,
  vegcurrys_dal_tadka,
  currys_egg_curry,
  currys_egg_masala,
  masala_omelt,
  plane_omlet,
  boiled_egg,
  currys_fish_curry,
  currys_fish_masala,
  fishstarters_apollo_fish,
  fishstarters_chilly_fish,
  fishstarters_fish_finger,
  fishstarters_fish_manchuria,
  fishstarters_fish_pakoda,
  fishstarters_garlic_fish,
  fishstarters_ginger_fish,
  kabab_grill_chicken_tikka,
  kabab_grill_malai_kabab,
  kabab_grill_tandoori_chicken,
  kabab_grill_tangdi_kabab,
  kakab_grill_banjara_tikka,
  currys_mutton_masala,
  currys_andhra_mutton,
  currys_telangana_mutton,
  currys_mutton_curry,
  currys_kadai_mutton,
  muttonstarter_muttonmunchurian,
  muttonstarters_muttonchilli,
  muttonstarters_sechzwanmutton,
  non_veg_starters_chicken_hakka_starter,
  non_veg_noodles_chicken_sezwan_noodles,
  non_veg_noodles_chicken_noodles,
  non_veg_noodles_chicken_american_choupsuey,
  no_veg_starters_mixnon_veg_choupsuey,
  nonveg_soups_chickenmanchow_soup,
  nonveg_soups_chicken_lung_fung_soup,
  nonveg_soups_mix_nonveg_soup,
  nonveg_soups_chicken_hot_and_sour_soup,
  nonveg_soups_chicken_coriander_lemon_soup,
  nonveg__soups_chickenthai_soup,
  nonveg_corn_soup,
  nonveg_chicken_sweet_corn_soup,
  currys_kaju_panner,
  currys_panner_tikka_masala,
  currys_panner_methi_chaman,
  currys_panner_kofta,
  currys_panner_butter_masala,
  biryanis_prawns_biryani,
  nonveg_currys_prawns_masala,
  nonveg_currys_prawns_curry,
  nonveg_currys_butter_prawns,
  salads_spl_salad,
  salads_green_salad,
  splfishtikka_items_nawrani_fish_tikka,
  splfishtikka_items_gralic_fish_tikka,
  splfishtikka_itemsbanjara_fish_tikka,
  spl_biryanis_tangdi_biryani,
  spl_biryanis_fry_piece_biryani,
  spl_biryanis_egg_biryani,
  spl_biryanis_chicken_tikka_biryani,
  spl_biryanis_chicken_lollipop_biryani,
  spl_biryanis_chicken_boneless_biryani,
  spl_biryanis_chicken65biryani,
  vegnoodles_veg_soft_noodles,
  vegnoodles_vegsezwannoodles,
  vegnoodles_veg_mix_noodles,
  vegnoodles_veg_hakka_noodles,
  vegnoodles_American_Chopsuey,
  vegsoups_veg_manchow_soup,
  vegsoups_veg_dragon_soup,
  vegsoups_thai_soup,
  vegsoups_tamato_soup,
  vegsoups_sweet_corn_soup,
  vegsoups_lung_fung_soup,
  vegsoups_lemon_coriander_soup,
  vegsoups_hot_sour_soups,
  vegsoups_cream_of_mushroom_soup,
  vegstarters_panner_65,
  vegstarters_mushroom_65,
  vegstarters_gobi_65,
  vegstarters_chilly_baby_corn,
  biryanis_chicken_biryani_family_pack,
  biryanis_fish_biryani_single,
  biryanis_mutton_biryani_family_pack,
  papads_fry_papad,
  papads_masala_papad,
  papads_roasted_papad,
  veg_biryanis_kaju_biryani,
  veg_biryanis_panner_biryani,
  veg_riceitems_veg_biryani,
  vey_currys_mushroom_masala,
  vegcurrys_babycornmushhro,
  vegcurrys_vegkholapuri,
  veg_currys_veg_chatpata,
  veg_currys_panner_kofta,
  veg_currys_mix_veg_curry,
  veg_currys_malai_kofta,
  veg_currys_kadi_mushroom,
  veg_currys_alu_mutter_masala,
  veg_currys_alu_gobi_masala,
  veg_currys_mushroom_kholapuri,
  vegriceitems_zeera_rice,
  veg_riceitems_veg_fried_rice,
  vegriceitems_sezwan_frid_rice,
  vegriceitems_kaju_frid_rice,
  baby_corn_manchuria,
  chilly_baby_corn,
  gobi_65,
  veg_starters_chilly_mushroom,
  veg_starters_chilly_panner,
  veg_starters_crispy_corn,
  veg_starters_mushroom_manchuria,
  veg_starters_veg_65,
  vegstarters_gobimanchuria,
  vegstarters_houslapannermajic,
  vegstarters_mush_roomkholapuri,
  vegstarters_vegmanchuria,
  vegstarters_spl_panneroctopus,
  vegstarters_baby_corn_manchuria,
  currys_hyd_panner,
  currys_kadai_panner,
  currys_panner_chatpata,
  currys_panner_dilroba,
  currys_shahi_panner,
  panner_currys_palak_panner,
  prawnsstarters_royala_vepudu,
  prawnsstarters_prawns_pakoda,
  prawnsstarters_prawns_manchuria,
  prawnsstarters_loose_prawns,
  prawnsstarters_ginger_prawns,
  prawnsstarters_chilly_prawns,
  chilly_baby_cornn,
  image_9,
  nonveg_biryanis_mushroom_biryani,
  nonveg_currys_egg_curry,
  nonveg_currys_egg_masala,
  nonveg_riceitems_chicken_fried_rice,
  nonveg_riceitems_chicken_sezwan_fried_rice,
  nonveg_riceitems_egg_fried_rice,
  nonveg_riceitems_mix_nonveg_fried_rice,
  nonveg_starters_chicken_drumsticks,
  vegriceitems_curd_rice,
  vegstarters_chilly_gobi,
  vegstarters_veg_65,
} from "./allImages";

const category = {
  ChickenBiryanis: [
    {
      name: "Single chicken Biryani",
      category: "chicken",
      price: 160,
      image: biryanis_chicken_biryani_family_pack,
    },
    {
      name: "Chicken Biryani Handi",
      category: "chicken",
      price: 310,
      image: biryanis_chicken_biryani_family_pack,
    },

    {
      name: "Chicken Family Pack",
      category: "Chicken",
      price: 600,
      image: biryanis_chicken_biryani_family_pack,
    },
    {
      name: "Chicken Jumbo Pack",
      category: "chicken",
      price: 800,
      image: biryanis_chicken_biryani_family_pack,
    },
  ],
  MuttonBiryanis: [
    {
      name: "Single Mutton Biryani",
      category: "Mutton",
      price: 190,
      image: biryanis_mutton_biryani_family_pack,
    },
    {
      name: "Mutton Biryani Handi",
      category: "Mutton",
      price: 370,
      image: biryanis_mutton_biryani_family_pack,
    },
    {
      name: "Mutton Family Pack",
      category: "Mutton",
      price: 650,
      image: biryanis_mutton_biryani_family_pack,
    },
    {
      name: "Mutton Jumbo Pack",
      category: "Mutton",
      price: 860,
      image: biryanis_mutton_biryani_family_pack,
    },
  ],

  SplBiryanis: [
    {
      name: "Egg Biryani",
      category: "SplBiryani",
      price: 140,
      image: spl_biryanis_egg_biryani,
    },
    {
      name: "Tikka Biryani",
      category: "SplBiryani",
      price: 370,
      image: spl_biryanis_chicken_tikka_biryani,
    },
    {
      name: "Chicken Fry Piece Biryani",
      category: "SplBiryani",
      price: 370,
      image: spl_biryanis_fry_piece_biryani,
    },
    {
      name: "Tangdi Biryani",
      category: "SplBiryani",
      price: 380,
      image: spl_biryanis_tangdi_biryani,
    },
    {
      name: "Spl Lolipop Biryani",
      category: "SplBiryani",
      price: 390,
      image: spl_biryanis_chicken_lollipop_biryani,
    },
    {
      name: "Spl Chicken 65 Biryani",
      category: "SplBiryani",
      price: 390,
      image: spl_biryanis_chicken65biryani,
    },
    {
      name: "Spl Boneless Biryani",
      category: "SplBiryani",
      price: 390,
      image: spl_biryanis_chicken_boneless_biryani,
    },
  ],

  PrawnsBiryanis: [
    {
      name: "Single Prawns Biryani",
      category: "PrawnsBiryani",
      price: 240,
      image: biryanis_prawns_biryani,
    },
    {
      name: "Prawns Biryani Full",
      category: "PrawnsBiryani",
      price: 390,
      image: biryanis_prawns_biryani,
    },
  ],

  FishBiryanis: [
    {
      name: "Single Fish Biryani",
      category: "FishBiryani",
      price: 240,
      image: biryanis_fish_biryani_single,
    },
    {
      name: "Fish Biryani Full",
      category: "FishBiryani",
      price: 390,
      image: biryanis_fish_biryani_single,
    },
  ],
  VegBiryanis: [
    {
      name: "Single Veg Biryani",
      category: "VegBiryanis",
      price: 140,
      image: veg_riceitems_veg_biryani,
    },
    {
      name: "Veg Biryani Handi",
      category: "VegBiryanis",
      price: 240,
      image: veg_riceitems_veg_biryani,
    },

    {
      name: "Single Panner Biryani ",
      category: "VegBiryanis",
      price: 210,
      image: veg_biryanis_panner_biryani,
    },
    {
      name: "Panner Biryani Full",
      category: "VegBiryanis",
      price: 310,
      image: veg_biryanis_panner_biryani,
    },

    {
      name: "Mushroom Biryani",
      category: "VegBiryanis",
      price: 290,
      image: nonveg_biryanis_mushroom_biryani,
    },
    {
      name: "Kaju Biryani",
      category: "VegBiryanis",
      price: 310,
      image: veg_biryanis_kaju_biryani,
    },
  ],

  VegSoups: [
    {
      name: "Hot N Sour Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_hot_sour_soups,
    },
    {
      name: "Manchow Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_veg_manchow_soup,
    },
    {
      name: "Sweet Corn Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_sweet_corn_soup,
    },
    {
      name: "Veg Corn Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_sweet_corn_soup,
    },
    {
      name: "Tomato Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_tamato_soup,
    },
    {
      name: "Lung Fung Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_lung_fung_soup,
    },
    {
      name: "Thai Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_thai_soup,
    },
    {
      name: "Lemon Coriander Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_lemon_coriander_soup,
    },
    {
      name: "Veg Dragon Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_veg_dragon_soup,
    },
    {
      name: "Cream Of Mushroom Soup",
      category: "VegSoups",
      price: 110,
      image: vegsoups_cream_of_mushroom_soup,
    },
  ],

  NonVegSoups: [
    {
      name: "Chicken Hot N Sour Soup",
      category: "NonVegSoups",
      price: 130,
      image: nonveg_soups_chicken_hot_and_sour_soup,
    },
    {
      name: "Chicken Manchow Soup",
      category: "NonVegSoups",
      price: 130,
      image: nonveg_soups_chickenmanchow_soup,
    },
    {
      name: "Chicken Sweet Corn Soup",
      category: "NonVegSoups",
      price: 130,
      image: nonveg_chicken_sweet_corn_soup,
    },
    {
      name: "Chicken Corn Soup",
      category: "NonVegSoups",
      price: 130,
      image: nonveg_corn_soup,
    },
    {
      name: "Chicken Lung Fung Soup",
      category: "NonVegSoups",
      price: 130,
      image: nonveg_soups_chicken_lung_fung_soup,
    },
    {
      name: "Chicken Thai Soup",
      category: "NonVegSoups",
      price: 130,
      image: nonveg__soups_chickenthai_soup,
    },
    {
      name: "Chicken Lemon Coriander Soup",
      category: "NonVegSoups",
      price: 130,
      image: nonveg_soups_chicken_coriander_lemon_soup,
    },
    {
      name: "Mix Non Veg Soup",
      category: "NonVegSoups",
      price: 150,
      image: nonveg_soups_mix_nonveg_soup,
    },
  ],

  VegNoodles: [
    {
      name: "Veg Soft Noodles",
      category: "VegNoodles",
      price: 160,
      image: vegnoodles_veg_soft_noodles,
    },
    {
      name: "Veg Sezwan Noodles",
      category: "VegNoodles",
      price: 180,
      image: vegnoodles_vegsezwannoodles,
    },
    {
      name: "Veg Hakka Noodles",
      category: "VegNoodles",
      price: 180,
      image: vegnoodles_veg_hakka_noodles,
    },
    {
      name: "Mix Veg Noodles",
      category: "VegNoodles",
      price: 190,
      image: vegnoodles_veg_mix_noodles,
    },
    {
      name: "American Chopsury",
      category: "VegNoodles",
      price: 220,
      image: vegnoodles_American_Chopsuey,
    },
  ],

  NonVegNoodles: [
    {
      name: "Chicken Noodles",
      category: "NonVegNoodles",
      price: 190,
      image: non_veg_noodles_chicken_noodles,
    },
    {
      name: "Chicken Swezan Noodles",
      category: "NonVegNoodles",
      price: 200,
      image: non_veg_noodles_chicken_sezwan_noodles,
    },
    {
      name: "Chicken Haak Noodles",
      category: "NonVegNoodles",
      price: 200,
      image: non_veg_starters_chicken_hakka_starter,
    },
    {
      name: "Mix Non Veg Noodles",
      category: "NonVegNoodles",
      price: 220,
      image: non_veg_noodles_chicken_noodles,
    },
    {
      name: "Chicken Americal choupsy",
      category: "NonVegNoodles",
      price: 260,
      image: non_veg_noodles_chicken_american_choupsuey,
    },
    {
      name: "Mix Non Veg choupsy",
      category: "NonVegNoodles",
      price: 300,
      image: no_veg_starters_mixnon_veg_choupsuey,
    },
  ],
  Salads: [
    {
      name: "Green Salad",
      category: "Salads",
      price: 60,
      image: salads_green_salad,
    },
    {
      name: "Spl Salad",
      category: "Salads",
      price: 80,
      image: salads_spl_salad,
    },
  ],
  VegRiceItems: [
    {
      name: "Veg Fried Rice",
      category: "VegRiceItems",
      price: 140,
      image: veg_riceitems_veg_fried_rice,
    },
    {
      name: "Veg Sezwan Fried Rice",
      category: "VegRiceItems",
      price: 160,
      image: vegriceitems_sezwan_frid_rice,
    },
    {
      name: "Zeera Rice",
      category: "VegRiceItems",
      price: 200,
      image: vegriceitems_zeera_rice,
    },
    {
      name: "Curd Rice",
      category: "VegRiceItems",
      price: 150,
      image: vegriceitems_curd_rice,
    },
    {
      name: "Kaju Fried Rice",
      category: "VegRiceItems",
      price: 220,
      image: vegriceitems_kaju_frid_rice,
    },
  ],
  NonVegRiceItems: [
    {
      name: "Egg Fried Rice",
      category: "NonVegRiceItems",
      price: 150,
      image: nonveg_riceitems_egg_fried_rice,
    },
    {
      name: "Chicken Fried Rice",
      category: "NonVegRiceItems",
      price: 170,
      image: nonveg_riceitems_chicken_fried_rice,
    },
    {
      name: "Chicken Sezwan Fried Rice",
      category: "NonVegRiceItems",
      price: 180,
      image: nonveg_riceitems_chicken_sezwan_fried_rice,
    },
    {
      name: "Mix Non-Veg Fried Rice",
      category: "NonVegRiceItems",
      price: 250,
      image: nonveg_riceitems_mix_nonveg_fried_rice,
    },
  ],
  Papads: [
    {
      name: "Fry Papad",
      category: "papads",
      price: 40,
      image: papads_fry_papad,
    },
    {
      name: "Roasted Papad",
      category: "papads",
      price: 40,
      image: papads_roasted_papad,
    },
    {
      name: "Masala Papad",
      category: "papads",
      price: 60,
      image: papads_masala_papad,
    },
  ],

  PannerCurrys: [
    {
      name: "Palak Paneer",
      category: "PannerCurrys",
      price: 260,
      image: panner_currys_palak_panner,
    },
    {
      name: "Kadai Paneer",
      category: "PannerCurrys",
      price: 260,
      image: currys_kadai_panner,
    },
    {
      name: "Shahi Paneer Khoma",
      category: "PannerCurrys",
      price: 260,
      image: currys_shahi_panner,
    },
    {
      name: "Panner Chatpata",
      category: "PannerCurrys",
      price: 260,
      image: currys_panner_chatpata,
    },
    {
      name: "Panner Butter Masala",
      category: "PannerCurrys",
      price: 260,
      image: currys_panner_butter_masala,
    },
    {
      name: "Panner Tikka Masala",
      category: "PannerCurrys",
      price: 270,
      image: currys_panner_tikka_masala,
    },
    {
      name: "Kaju Panner",
      category: "PannerCurrys",
      price: 280,
      image: currys_kaju_panner,
    },
    {
      name: "Panner Kofta",
      category: "PannerCurrys",
      price: 280,
      image: currys_panner_kofta,
    },
    {
      name: "Panner Dilroba",
      category: "PannerCurrys",
      price: 280,
      image: currys_panner_dilroba,
    },
    {
      name: "Panner Methi Chaman",
      category: "PannerCurrys",
      price: 280,
      image: currys_panner_methi_chaman,
    },
    {
      name: "Hyderabad Paneer",
      category: "PannerCurrys",
      price: 290,
      image: currys_hyd_panner,
    },
  ],

  EggCurrys: [
    {
      name: "Egg Curry",
      category: "EggCurrys",
      price: 170,
      image: nonveg_currys_egg_curry,
    },
    {
      name: "Egg Masala",
      category: "EggCurrys",
      price: 170,
      image: nonveg_currys_egg_masala,
    },
  ],

  PrawnsCurrys: [
    {
      name: "Prawns Curry",
      category: "PrawnsCurrys",
      price: 340,
      image: nonveg_currys_prawns_curry,
    },
    {
      name: "Prawns masala",
      category: "PrawnsCurrys",
      price: 340,
      image: nonveg_currys_prawns_masala,
    },
    {
      name: "Butter Prawns",
      category: "PrawnsCurrys",
      price: 350,
      image: nonveg_currys_butter_prawns,
    },
  ],

  VegCurrys: [
    {
      name: "Mix Veg Curry",
      category: "VegCurrys",
      price: 200,
      image: veg_currys_mix_veg_curry,
    },
    {
      name: "Veg Chatpata",
      category: "VegCurrys",
      price: 200,
      image: veg_currys_veg_chatpata,
    },
    {
      name: "Alu Gobi Masala",
      category: "VegCurrys",
      price: 200,
      image: veg_currys_alu_gobi_masala,
    },
    {
      name: "Panner Kofta",
      category: "VegCurrys",
      price: 240,
      image: veg_currys_panner_kofta,
    },
    {
      name: "Veg Malai Kofta",
      category: "VegCurrys",
      price: 240,
      image: veg_currys_malai_kofta,
    },
    {
      name: "Alu Mutter Masala",
      category: "VegCurrys",
      price: 240,
      image: veg_currys_alu_mutter_masala,
    },
    {
      name: "Mushroom Masala",
      category: "VegCurrys",
      price: 260,
      image: vey_currys_mushroom_masala,
    },
    {
      name: "Kadi Mushroom",
      category: "VegCurrys",
      price: 260,
      image: veg_currys_kadi_mushroom,
    },
    {
      name: "Mushroom Kholapuri",
      category: "VegCurrys",
      price: 280,
      image: veg_currys_mushroom_kholapuri,
    },
    {
      name: "Veg Kholapuri",
      category: "VegCurrys",
      price: 260,
      image: vegcurrys_vegkholapuri,
    },
    {
      name: "Baby Corn Mushroom",
      category: "VegCurrys",
      price: 270,
      image: vegcurrys_babycornmushhro,
    },
    {
      name: "Baby Corn Masala",
      category: "VegCurrys",
      price: 270,
      image: veg_starters_crispy_corn,
    },
  ],

  ChickenCurrys: [
    {
      name: "Chicken Curry",
      category: "ChickenCurrys",
      price: 250,
      image: chickencurries_chicken_curry,
    },
    {
      name: "Dum Ka Chicken",
      category: "ChickenCurrys",
      price: 260,
      image: chickencurries_dum_ka_chicken,
    },
    {
      name: "Kadai Chicken",
      category: "ChickenCurrys",
      price: 260,
      image: chickencurries_kadai_chicken,
    },
    {
      name: "Butter Chicken",
      category: "ChickenCurrys",
      price: 260,
      image: chicken_curries_butter_chicken,
    },
    {
      name: "Chicken Chatpata",
      category: "ChickenCurrys",
      price: 260,
      image: chickencurries_chicken_chutpata,
    },
    {
      name: "Telangana Chicken",
      category: "ChickenCurrys",
      price: 270,
      image: chickencurries_telanaganachicken,
    },
    {
      name: "Andhara Chicken",
      category: "ChickenCurrys",
      price: 270,
      image: chickencurries_andhrachicken,
    },
    {
      name: "Pubjabi Chicken",
      category: "ChickenCurrys",
      price: 270,
      image: chickencurries_punjabi_chicken,
    },
    {
      name: "Tandoori Chicken Masala",
      category: "ChickenCurrys",
      price: 270,
      image: chickencurries_tandoori_chickenmasala,
    },
    {
      name: "Chicken Tikka masala",
      category: "ChickenCurrys",
      price: 270,
      image: chickencurries_chicken_tikka_masala,
    },
    {
      name: "Methi Chicken",
      category: "ChickenCurrys",
      price: 270,
      image: chickencurries_methi_chicken,
    },
    {
      name: "Ginger Chicken",
      category: "ChickenCurrys",
      price: 270,
      image: chickencurries_ginger_chicken,
    },
    {
      name: "Chicken Lajawab",
      category: "ChickenCurrys",
      price: 270,
      image: chicken_curries_chicken_lajawab,
    },
    {
      name: "Chicken Mughlai",
      category: "ChickenCurrys",
      price: 270,
      image: chicken_curries_mughlai_chicken,
    },
    {
      name: "Chicken Afghani",
      category: "ChickenCurrys",
      price: 280,
      image: chickencurries_afgani_chicken,
    },
    {
      name: "Spl Thalka Chicken",
      category: "ChickenCurrys",
      price: 300,
      image: Chichencurries_spl_thalka_chciken,
    },
    {
      name: "Spl Murg Muslam",
      category: "ChickenCurrys",
      price: 310,
      image: chickencurries_spl_murg_musalam,
    },
    {
      name: "Chicken Tandi Masala",
      category: "ChickenCurrys",
      price: 310,
      image: chickencurries_chicken_tandi,
    },
    {
      name: "Chicken Kholapuri",
      category: "ChickenCurrys",
      price: 300,
      image: chickencurries_chicken_kholapuri,
    },
    {
      name: "Spl Begum Chicken",
      category: "ChickenCurrys",
      price: 310,
      image: chickencurries_spl_begum_chicken,
    },
  ],

  MuttonCurrys: [
    {
      name: "Mutton Curry",
      category: "MuttonCurrys",
      price: 310,
      image: currys_mutton_curry,
    },
    {
      name: "Mutton Masala",
      category: "MuttonCurrys",
      price: 310,
      image: currys_mutton_masala,
    },
    {
      name: "Kadai Mutton",
      category: "MuttonCurrys",
      price: 310,
      image: currys_kadai_mutton,
    },
    {
      name: "Telangana Mutton",
      category: "MuttonCurrys",
      price: 310,
      image: currys_telangana_mutton,
    },
    {
      name: "Andhra Mutton",
      category: "MuttonCurrys",
      price: 310,
      image: currys_andhra_mutton,
    },
    {
      name: "Mutton Afghani",
      category: "MuttonCurrys",
      price: 320,
      image: currys_andhra_mutton,
    },
  ],

  FishCurrys: [
    {
      name: "Fish Curry",
      category: "FishCurrys",
      price: 390,
      image: currys_fish_curry,
    },
    {
      name: "Fish Masala",
      category: "FishCurrys",
      price: 340,
      image: currys_fish_masala,
    },
  ],

  EggItems: [
    {
      name: "Plane Omlet",
      category: "EggItems",
      price: 70,
      image: plane_omlet,
    },
    {
      name: "Masala Omlet",
      category: "EggItems",
      price: 80,
      image: masala_omelt,
    },
    {
      name: "Boiled Egg",
      category: "EggItems",
      price: 60,
      image: boiled_egg,
    },
  ],

  VegStarters: [
    {
      name: "Veg 65",
      category: "VegStarters",
      price: 210,
      image: vegstarters_veg_65,
    },
    {
      name: "Gobi 65",
      category: "VegStarters",
      price: 210,
      image: gobi_65,
    },
    {
      name: "Chilly Gobi",
      category: "VegStarters",
      price: 210,
      image: vegstarters_chilly_gobi,
    },
    {
      name: "Veg Manchuria",
      category: "VegStarters",
      price: 210,
      image: vegstarters_vegmanchuria,
    },
    {
      name: "Gobi Manchuria",
      category: "VegStarters",
      price: 210,
      image: vegstarters_gobimanchuria,
    },
    {
      name: "Crispy Corn",
      category: "VegStarters",
      price: 210,
      image: veg_starters_crispy_corn,
    },
    {
      name: "Panner 65",
      category: "VegStarters",
      price: 260,
      image: vegstarters_panner_65,
    },
    {
      name: "Chilly Panner",
      category: "VegStarters",
      price: 260,
      image: veg_starters_chilly_panner,
    },
    {
      name: "Housal Panner Majic",
      category: "VegStarters",
      price: 270,
      image: vegstarters_houslapannermajic,
    },
    {
      name: "Mushroom 65",
      category: "VegStarters",
      price: 270,
      image: vegstarters_mushroom_65,
    },
    {
      name: "Chilly Mushroom",
      category: "VegStarters",
      price: 270,
      image: veg_starters_chilly_mushroom,
    },
    {
      name: "Mushroom Manchuria",
      category: "VegStarters",
      price: 270,
      image: veg_starters_mushroom_manchuria,
    },
    {
      name: "Baby Corn Manchuria",
      category: "VegStarters",
      price: 270,
      image: vegstarters_baby_corn_manchuria,
    },
    {
      name: "Chilly Baby Corn",
      category: "VegStarters",
      price: 270,
      image: chilly_baby_cornn,
    },
    {
      name: "Spl Panner Octopus",
      category: "VegStarters",
      price: 390,
      image: vegstarters_spl_panneroctopus,
    },
  ],

  ChickenStarters: [
    {
      name: "Chicken 65",
      category: "ChickenStarters",
      price: 260,
      image: chickenstarters_chicken65,
    },
    {
      name: "Chicken Manchuria",
      category: "ChickenStarters",
      price: 260,
      image: chickenstarters_chickenmanchuria,
    },
    {
      name: "Chilly Chicken",
      category: "ChickenStarters",
      price: 260,
      image: chickenstarters_chillichicken,
    },
    {
      name: "Chicken Majestic",
      category: "ChickenStarters",
      price: 260,
      image: chickenstareter_chickenmajestic,
    },
    {
      name: "Chicken 555",
      category: "ChickenStarters",
      price: 280,
      image: chickenstarter_chicken555,
    },
    {
      name: "Chicken 8 to 8",
      category: "ChickenStarters",
      price: 280,
      image: chickenstarter_chicken8to8,
    },
    {
      name: "Crispy Fry Chicken",
      category: "ChickenStarters",
      price: 260,
      image: chickenstarters_crispyfrychicken,
    },
    {
      name: "Pepeer Chicken",
      category: "ChickenStarters",
      price: 280,
      image: chickenstarters_pepperchicken,
    },
    {
      name: "Housal Murgi Majic",
      category: "ChickenStarters",
      price: 290,
      image: chickenstarters_housla_chicken,
    },
    {
      name: "Apollo Chicken",
      category: "ChickenStarters",
      price: 290,
      image: chickenstarters_apollochicken,
    },
    {
      name: "Kfc Chicken",
      category: "ChickenStarters",
      price: 290,
      image: chickenstarters__kfcchicken,
    },
    {
      name: "Chicken Lolipop Half",
      category: "ChickenStarters",
      price: 240,
      image: chickenstarter_chicken_lolliop,
    },
    {
      name: "Chicken Lolipop Full",
      category: "ChickenStarters",
      price: 420,
      image: chickenstarter_chicken_lolliop,
    },
    {
      name: "Chicken Drumstick Half",
      category: "ChickenStarters",
      price: 240,
      image: nonveg_starters_chicken_drumsticks,
    },
    {
      name: "Chicken Drumstick Full",
      category: "ChickenStarters",
      price: 420,
      image: nonveg_starters_chicken_drumsticks,
    },
    {
      name: "Spl Chicken Octopus",
      category: "ChickenStarters",
      price: 310,
      image: chickenstarters_chickenoctopus,
    },
  ],

  MuttonStarters: [
    {
      name: "Mutton Chilly",
      category: "MuttonStarters",
      price: 390,
      image: muttonstarters_muttonchilli,
    },
    {
      name: "Mutton manchuria",
      category: "MuttonStarters",
      price: 390,
      image: muttonstarter_muttonmunchurian,
    },
    {
      name: "Sechzwan Mutton",
      category: "MuttonStarters",
      price: 390,
      image: muttonstarters_sechzwanmutton,
    },
  ],
  PrawnsStarters: [
    {
      name: "Prawns Pakoda",
      category: "PrawnsStarters",
      price: 390,
      image: prawnsstarters_prawns_pakoda,
    },
    {
      name: "Ginger Prawns",
      category: "PrawnsStarters",
      price: 340,
      image: prawnsstarters_ginger_prawns,
    },
    {
      name: "Loose Prawns",
      category: "PrawnsStarters",
      price: 390,
      image: prawnsstarters_loose_prawns,
    },
    {
      name: "Prawns Manchuria",
      category: "PrawnsStarters",
      price: 390,
      image: prawnsstarters_prawns_manchuria,
    },
    {
      name: "Royal Vepudu",
      category: "PrawnsStarters",
      price: 390,
      image: prawnsstarters_royala_vepudu,
    },
    {
      name: "Chilly Prawns",
      category: "PrawnsStarters",
      price: 390,
      image: prawnsstarters_chilly_prawns,
    },
  ],
  FishStarters: [
    {
      name: "Fish Pakoda",
      category: "FishStarters",
      price: 390,
      image: fishstarters_fish_pakoda,
    },
    {
      name: "Fish Manchuria",
      category: "FishStarters",
      price: 390,
      image: fishstarters_fish_manchuria,
    },
    {
      name: "Apollo Fish",
      category: "FishStarters",
      price: 390,
      image: fishstarters_apollo_fish,
    },
    {
      name: "Ginger Fish",
      category: "FishStarters",
      price: 390,
      image: fishstarters_ginger_fish,
    },
    {
      name: "Garlic Fish",
      category: "FishStarters",
      price: 390,
      image: fishstarters_garlic_fish,
    },
    {
      name: "Chilly Fish",
      category: "FishStarters",
      price: 340,
      image: fishstarters_chilly_fish,
    },
    {
      name: "Fish Finger",
      category: "FishStarters",
      price: 340,
      image: fishstarters_fish_finger,
    },
  ],
  KababsAndGrills: [
    {
      name: "Tandoori Chicken Half",
      category: "Kababs&Grills",
      price: 240,
      image: kabab_grill_tandoori_chicken,
    },
    {
      name: "Tandoori Chicken Full",
      category: "Kababs&Grills",
      price: 420,
      image: kabab_grill_tandoori_chicken,
    },
    {
      name: "Tangdi Kabab Half",
      category: "Kababs&Grills",
      price: 240,
      image: kabab_grill_tangdi_kabab,
    },
    {
      name: "Tangdi Kabab Full",
      category: "Kababs&Grills",
      price: 420,
      image: kabab_grill_tangdi_kabab,
    },
    {
      name: "Haryali Kabab",
      category: "Kababs&Grills",
      price: 400,
      image: kakab_grill_banjara_tikka,
    },
    {
      name: "Malai Kabab",
      category: "Kababs&Grills",
      price: 400,
      image: kabab_grill_malai_kabab,
    },
    {
      name: "Reshmi Kabab",
      category: "Kababs&Grills",
      price: 400,
      image: kabab_grill_tangdi_kabab,
    },
    {
      name: "Chicken Kabab",
      category: "Kababs&Grills",
      price: 400,
      image: kabab_grill_chicken_tikka,
    },
    {
      name: "Chicken Tikka",
      category: "Kababs&Grills",
      price: 400,
      image: kabab_grill_chicken_tikka,
    },
    {
      name: "Murg Lahori Tikka",
      category: "Kababs&Grills",
      price: 400,
      image: kabab_grill_malai_kabab,
    },
    {
      name: "Banjara Murg Tikka",
      category: "Kababs&Grills",
      price: 400,
      image: kabab_grill_malai_kabab,
    },
  ],

  SplFishTikkaItems: [
    {
      name: "Nawrani Fish Tikka",
      category: "SplTikkaItems",
      price: 400,
      image: splfishtikka_items_nawrani_fish_tikka,
    },
    {
      name: "Banjara Fish Tikka",
      category: "SplTikkaItems",
      price: 400,
      image: splfishtikka_itemsbanjara_fish_tikka,
    },
    {
      name: "Garlic Fish Tikka",
      category: "SplTikkaItems",
      price: 400,
      image: splfishtikka_items_gralic_fish_tikka,
    },
  ],
  DalItems: [
    {
      name: "Dal Chatpata",
      category: "DalItems",
      price: 170,
      image: vegcurrys_dal_chatpata,
    },
    {
      name: "Dal Fry",
      category: "DalItems",
      price: 170,
      image: vegcurrys_dal_fry,
    },
    {
      name: "Dal Tadka",
      category: "DalItems",
      price: 170,
      image: vegcurrys_dal_tadka,
    },
  ],
};

export default category;
