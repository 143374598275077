import "./App.css";
import Greeting from "./navbar/Greeting";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./navbar/Layout";
import { useEffect, useRef, useState } from "react";
import Home from "./Home";
import CategoryPage from "./CategoryPage";

function App() {
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1064);

  const handleResize = (e) => {
    setSmallScreen(window.innerWidth < 1064);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const endRef = useRef(null);
  return (
    <BrowserRouter>
      {smallScreen ? (
        <Greeting bestRef={endRef} />
      ) : (
        <Layout bestRef={endRef} />
      )}

      <Routes>
        <Route
          path="/"
          element={<Home endRef={endRef} smallScreen={smallScreen} />}
        />
        <Route
          path="/category/:id"
          element={<CategoryPage endRef={endRef} smallScreen={smallScreen} />}
        />
      </Routes>

      {/* {smallScreen ? <MobileFooter /> : <Footer />} */}
    </BrowserRouter>
  );
}

export default App;
