import React, { useState } from "react";
import Banner1 from "./images/Banners/one.png";
import Banner2 from "./images/Banners/two.png";
import Banner3 from "./images/Banners/three.png";
import Desk1 from "./images/Banners/desk2.jpg";
import Desk2 from "./images/Banners/desk3.jpg";
import Desk3 from "./images/Banners/desk1.jpg";
import {
  Non_Veg_Soups120,
  Non_Veg_Soups150,
  Prawns_Starters1200,
  Prawns_Starters1500,
  Fish_Starters120,
  Fish_Starters150,
  Egg_Items120,
  Egg_Items150,
  Kababs_Grills120,
  Kababs_Grills150,
  Spl_Fish_Tikka120,
  Spl_Fish_Tikka150,
  Chicken_Starters120,
  Chicken_Starters150,
  Mutton_Starters120,
  Mutton_Starters150,
  Fish_Currys120,
  Fish_Currys150,
  Prawns_Currys120,
  Prawns_Currys150,
  Non_Veg_Noodles120,
  Non_Veg_Noodles150,
  Mutton_Biryanis120,
  Mutton_Biryanis150,
  Prawns_Biryanis120,
  Prawns_Biryanis150,
  Fish_Biryanis120,
  Fish_Biryanis150,
  Spl_Biryanis120,
  Spl_Biryanis150,
  Egg_Curry120,
  Egg_Curry150,
  chicken_curry_jx9pd4120,
  chicken_curry_1_Copy_y4vbgs150,
} from "./imagesImport";

import {
  Veg_Soups120,
  Veg_Soups150,
  Veg_Starters120,
  Veg_Starters150,
  Veg_Curry120,
  Veg_Curry150,
  Panner_Currys120,
  Panner_Currys150,
  Veg_Noodles120,
  Veg_Noodles150,
  Veg_Biryanis120,
  Veg_Biryanis150,
  Papads120,
  Papads150,
  Salads120,
  Salads150,
  Veg_Rice_Items120,
  Veg_Rice_Items150,
  Non_Veg_Rice_Items120,
  Non_Veg_Rice_Items150,
  dal_items_b9yztq120,
  dal_items_wxa81f150,
} from "./vegImagesImport";

import "./styles.scss";
import CarouselBanner from "./CarouselBanner";
import { Link } from "react-router-dom";
import Testimonials from "./components/Testimonials";

export default function Home({ smallScreen }) {
  const bawarchiItems = [
    {
      name: "Veg Soups",
      category: "starters",
      subcategory: "veg",
      image120: Veg_Soups120,
      image150: Veg_Soups150,
    },
    {
      name: "Veg Starters",
      category: "starters",
      subcategory: "veg",
      image120: Veg_Starters120,
      image150: Veg_Starters150,
    },
    {
      name: "Veg Currys",
      category: "main-course",
      subcategory: "veg",
      image120: Veg_Curry120,
      image150: Veg_Curry150,
    },
    {
      name: "Panner Currys",
      category: "main-course",
      subcategory: "veg",
      image120: Panner_Currys120,
      image150: Panner_Currys150,
    },
    {
      name: "Veg Noodles",
      category: "main-course",
      subcategory: "veg",
      image120: Veg_Noodles120,
      image150: Veg_Noodles150,
    },
    {
      name: "Veg Biryanis",
      category: "main-course",
      subcategory: "veg",
      image120: Veg_Biryanis120,
      image150: Veg_Biryanis150,
    },
    {
      name: "Papads",
      category: "starters",
      subcategory: "veg",
      image120: Papads120,
      image150: Papads150,
    },
    {
      name: "Salads",
      category: "starters",
      subcategory: "veg",
      image120: Salads120,
      image150: Salads150,
    },
    {
      name: "Dal Items",
      category: "main-course",
      subcategory: "veg",
      image120: dal_items_b9yztq120,
      image150: dal_items_wxa81f150,
    },
    {
      name: "Veg RiceItems",
      category: "main-course",
      subcategory: "veg",
      image120: Veg_Rice_Items120,
      image150: Veg_Rice_Items150,
    },

    {
      name: "Non Veg Soups",
      category: "starters",
      subcategory: "non-veg",

      image120: Non_Veg_Soups120,
      image150: Non_Veg_Soups150,
    },
    {
      name: "Chicken Starters",
      category: "starters",
      subcategory: "non-veg",
      image120: Chicken_Starters120,
      image150: Chicken_Starters150,
    },
    {
      name: "Mutton Starters",
      category: "starters",
      subcategory: "non-veg",
      image120: Mutton_Starters120,
      image150: Mutton_Starters150,
    },
    {
      name: "Prawns Starters",
      category: "starters",
      subcategory: "non-veg",
      image120: Prawns_Starters1200,
      image150: Prawns_Starters1500,
    },
    {
      name: "Fish Starters",
      category: "starters",
      subcategory: "non-veg",
      image120: Fish_Starters120,
      image150: Fish_Starters150,
    },
    {
      name: "Egg Items",
      category: "starters",
      subcategory: "non-veg",
      image120: Egg_Items120,
      image150: Egg_Items150,
    },
    {
      name: "Kababs And Grills",
      category: "starters",
      subcategory: "non-veg",
      image120: Kababs_Grills120,
      image150: Kababs_Grills150,
    },
    {
      name: "Spl Fish Tikka Items",
      category: "starters",
      subcategory: "non-veg",
      image120: Spl_Fish_Tikka120,
      image150: Spl_Fish_Tikka150,
    },

    {
      name: "Egg Currys",
      category: "main-course",
      subcategory: "non-veg",
      image120: Egg_Curry120,
      image150: Egg_Curry150,
    },
    {
      name: "Chicken Currys",
      category: "main-course",

      subcategory: "non-veg",
      image120: chicken_curry_jx9pd4120,
      image150: chicken_curry_1_Copy_y4vbgs150,
    },
    {
      name: "Fish Currys",
      category: "main-course",

      subcategory: "non-veg",
      image120: Fish_Currys120,
      image150: Fish_Currys150,
    },
    {
      name: "Prawns Currys",
      category: "main-course",

      subcategory: "non-veg",
      image120: Prawns_Currys120,
      image150: Prawns_Currys150,
    },
    {
      name: "Non Veg Noodles",
      category: "main-course",
      subcategory: "non-veg",
      image120: Non_Veg_Noodles120,
      image150: Non_Veg_Noodles150,
    },
    {
      name: "Chicken Biryanis",
      category: "main-course",

      subcategory: "non-veg",
      image120:
        "https://b.zmtcdn.com/data/dish_images/d19a31d42d5913ff129cafd7cec772f81639737697.png?fit=around|120:120&crop=120:120;*,*",
      image150:
        "https://b.zmtcdn.com/data/dish_images/d19a31d42d5913ff129cafd7cec772f81639737697.png",
    },
    {
      name: "Mutton Biryanis",
      category: "main-course",

      subcategory: "non-veg",
      image120: Mutton_Biryanis120,
      image150: Mutton_Biryanis150,
    },
    {
      name: "Prawns Biryanis",
      category: "main-course",

      subcategory: "non-veg",
      image120: Prawns_Biryanis120,
      image150: Prawns_Biryanis150,
    },
    {
      name: "Fish Biryanis",
      category: "main-course",

      subcategory: "non-veg",
      image120: Fish_Biryanis120,
      image150: Fish_Biryanis150,
    },
    {
      name: "Spl Biryanis",
      category: "main-course",

      subcategory: "non-veg",
      image120: Spl_Biryanis120,
      image150: Spl_Biryanis150,
    },
    {
      name: "Non Veg RiceItems",
      category: "main-course",

      subcategory: "non-veg",
      image120: Non_Veg_Rice_Items120,
      image150: Non_Veg_Rice_Items150,
    },
  ];
  let images = [];

  // "https://res.cloudinary.com/duk9xkcp5/image/upload/v1692469472/A_New_Design_-_Made_with_PosterMyWall_6_ja8ott.jpg",
  //       "https://res.cloudinary.com/duk9xkcp5/image/upload/v1692469472/A_New_Design_-_Made_with_PosterMyWall_6_ja8ott.jpg",
  //       "https://res.cloudinary.com/duk9xkcp5/image/upload/v1692469472/A_New_Design_-_Made_with_PosterMyWall_6_ja8ott.jpg",
  //       "https://res.cloudinary.com/duk9xkcp5/image/upload/v1692469472/A_New_Design_-_Made_with_PosterMyWall_6_ja8ott.jpg",

  let w = window.innerWidth;
  w >= 540
    ? (images = [Desk1, Desk2, Desk3])
    : (images = [Banner1, Banner2, Banner3]);

  // const [categories, setCategories] = useState(
  //   bawarchiItems[
  //     localStorage.getItem("bawarchiCat")
  //       ? localStorage.getItem("bawarchiCat")
  //       : "nonVegItems"
  //   ]
  // );
  // const [selector, setSelector] = useState(
  //   localStorage.getItem("bawarchiCat")
  //     ? localStorage.getItem("bawarchiCat")
  //     : "nonVegItems"
  // );

  const [selector, setSelector] = useState(
    localStorage.getItem("selector")
      ? localStorage.getItem("selector")
      : "starters"
  );
  const [subSelector, setSubSelector] = useState(
    localStorage.getItem("subSelector")
      ? localStorage.getItem("subSelector")
      : "non-veg"
  );
  const initial = bawarchiItems.filter(
    (item) => item.category === selector && item.subcategory === subSelector
  );
  const [filteredItems, setFilteredItems] = useState(initial);

  return (
    <section>
      <div className="home-img md:mb-0 mb-2">
        <div className="md:h-[85vh] h-[90vh] flex  flex-col items-center justify-center ">
          <h1 className="text-white md:text-6xl text-4xl text-center font-bold ">
            Hi, Welcome To <span className="text-red-500">Wifi Bawarchi🫡</span>
          </h1>
          <h1 className="text-white lg:px-44 px-4 text-lg font-bold text-center py-5">
            Step into a world where each dish is a masterpiece, meticulously
            crafted to tantalize your taste buds. Our culinary philosophy
            revolves around embracing flavors, celebrating quality ingredients,
            and presenting dishes that tell a story of artistry and innovation.
          </h1>
        </div>
      </div>
      <div className={` w-full mx-auto  md:rounded md:px-4 md:py-3`}>
        <CarouselBanner autoSlide={true}>
          {images.map((s) => {
            return (
              <img
                src={s}
                className="md:rounded"
                style={{
                  backgroundPosition: "center",

                  backgroundSize: "cover",
                  width: "100%",
                }}
                alt="carousel-img"
              />
            );
          })}
        </CarouselBanner>
      </div>

      <div className="md:px-10 ">
        <div className="flex md:flex-row flex-col items-center justify-between my-4 md:px-0 px-5">
          <h2 className="h2 my-4 text-xl font-extrabold">Delicious Menu</h2>
          <div className="flex items-center justify-between space-x-3 my-4">
            <div className="flex  items-center justify-center bg-red-400 px-2 py-1 rounded-lg">
              <button
                className={`${
                  selector === "starters"
                    ? "bg-gradient-to-r from-green-500 to-cyan-600  text-white shadow-md"
                    : "bg-red-400"
                } font-semibold px-4 py-1.5 rounded-lg md:text-sm text-xs`}
                onClick={() => {
                  const items = bawarchiItems.filter(
                    (item) =>
                      item.category === "starters" &&
                      item.subcategory === subSelector
                  );
                  setFilteredItems(items);
                  setSelector("starters");
                  localStorage.setItem("selector", "starters");
                }}
              >
                Starters
              </button>
              <button
                className={`${
                  selector === "main-course"
                    ? "bg-gradient-to-r from-green-500 to-cyan-600  text-white shadow-md"
                    : "bg-red-400"
                } font-semibold px-4 py-1.5 rounded-lg md:text-sm text-xs`}
                onClick={() => {
                  const items = bawarchiItems.filter(
                    (item) =>
                      item.category === "main-course" &&
                      item.subcategory === subSelector
                  );
                  setFilteredItems(items);
                  setSelector("main-course");
                  localStorage.setItem("selector", "main-course");
                }}
              >
                Main Course
              </button>
            </div>
            <div className="flex  items-center justify-center bg-red-400 px-2 py-1 rounded-lg">
              <button
                className={`${
                  subSelector === "veg"
                    ? "bg-gradient-to-r from-green-500 to-cyan-600  text-white shadow-md "
                    : "bg-red-400"
                } font-semibold px-4 py-1.5 rounded-lg md:text-sm text-xs`}
                onClick={() => {
                  const items = bawarchiItems.filter(
                    (item) =>
                      item.subcategory === "veg" && item.category === selector
                  );
                  setFilteredItems(items);
                  setSubSelector("veg");
                  localStorage.setItem("subSelector", "veg");
                }}
              >
                Veg
              </button>
              <button
                className={`${
                  subSelector === "non-veg"
                    ? "bg-gradient-to-r from-green-500 to-cyan-600  text-white shadow-md"
                    : "bg-red-400"
                } font-semibold px-4 py-1.5 rounded-lg md:text-sm text-xs`}
                onClick={() => {
                  const items = bawarchiItems.filter(
                    (item) =>
                      item.subcategory === "non-veg" &&
                      item.category === selector
                  );
                  setFilteredItems(items);
                  setSubSelector("non-veg");
                  localStorage.setItem("subSelector", "non-veg");
                }}
              >
                Non Veg
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=" md:p-8 p-5 rounded-lg">
        <div className=" grid grid-cols-3 gap-0 md:grid-cols-5 lg:grid-cols-7 max-w-7xl mx-auto">
          {filteredItems?.map((item, i) => {
            let joined = item.name.replace(/\s/g, "");

            return (
              <Link to={`/category/${joined}`}>
                <div className="my-4 mx-4 flex flex-col items-center justify-center">
                  <img
                    src={smallScreen ? item.image120 : item.image150}
                    alt="image_i"
                  />
                  <p className="w-[6.6rem] text-center t2uncate my-2 md:text-sm text-xs font-semibold">
                    {item.name}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>

      <Testimonials />

      <footer className="bg-[#f82750]  shadow  ">
        <div className="w-full  mx-auto p-4 md:py-8 space-y-2 ">
          <div className="flex flex-col items-center justify-center">
            <a
              href="/"
              className="flex items-center  sm:mb-0  rtl:space-x-reverse "
            >
              <img
                src="https://res.cloudinary.com/dqupmzcrb/image/upload/e_auto_contrast,q_100/v1685348916/EASY_TYM-removebg-preview_sab2ie.png"
                className="h-10"
                alt="Easytym Logo"
              />
              <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                Easytym
              </span>
            </a>
          </div>

          <span className="block text-sm text-white text-center font-semibold">
            Do You Also Need a Website/App? Please click on phone number to
            contact us <a href="tel:+918919788492">+91-8919785676</a>.
          </span>

          <span className="block text-sm text-white text-center font-extralight">
            © 2024{" "}
            <a href="https://flowbite.com/" className="hover:underline">
              Easytym™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </section>
  );
}
