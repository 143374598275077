import React, { memo } from "react";
import { Link } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Menu } from "@headlessui/react";

import "./navbar.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";

const scrollNow = () => {
  return window.scrollTo(0, 0);
};
const Layout = () => {
  return (
    <>
      <ToastContainer position="top-center" />

      <div className="mb-[4rem]">
        <div className="mainHead bg-[#f82750]">
          {/* <div
            className={`flex items-center justify-between px-10 bg-white head2`}
          > */}
          <div className="flex items-center justify-between px-10">
            <Link to="/" onClick={scrollNow}>
              <p
                className="text-xl font-extrabold py-5 text-white"
                style={{
                  fontFamily: "'Lobster', sans-serif",
                  fontSize: "28px",
                }}
              >
                Wifi Bawarchi
              </p>
            </Link>
            <div className="pl-5 text-xl  text-white font-semibold flex items-center justify-center space-x-2">
              <FontAwesomeIcon icon={faLocation} size="sm" />
              <p className="text-lg">Shadnagar</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-between "> */}
          {/* <div className="flex items-center justify-center md:space-x-8 space-x-3 ">
                <Link to="/about-us">
                  <h1
                    className={` font-semibold md:text-lg text-xs text-white`}
                  >
                    About
                  </h1>
                </Link>
                <Link to="/contact-us">
                  <h1 className={` font-semibold md:text-lg text-white`}>
                    Contact
                  </h1>
                </Link> */}
          {/* <button onClick={handleLanguageChange}>Change Language</button> */}

          {/* <Menu as="div" className="relative inline-block  z-10 ">
                  <Menu.Button className="flex items-center space-x-2 justify-center font-semibold capitalize mr-1">
                    <h1 className="md:text-lg text-xs text-white "> Hiiiiii</h1>
                    <FontAwesomeIcon
                      icon={faChevronCircleDown}
                      size="sm"
                      color={"black"}
                    />
                  </Menu.Button>
                  <Menu.Items className="absolute right-0 w-56 origin-top-right bg-gray-100  shadow-lg ">
                    <Menu.Item>
                      <DropdownLink className="dropdown-link p-1 mt-2">
                      <Link className="dropdown-link p-1 mt-2" to="/profile">
                        Profile
                      </Link>
                      </DropdownLink>
                    </Menu.Item>
                  </Menu.Items>
                </Menu> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default memo(Layout);
