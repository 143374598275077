import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import category from "./menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import Soup from "./images/vegsoups_cream-of-mushroom-soup.jpg";
const CategoryPage = () => {
  const { id } = useParams();

  const items = category[id];
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [searchedResults, setSearchedResults] = useState(items);

  const filterPrompts = (searchtext, maxPrice) => {
    const regex = new RegExp(searchtext, "i"); // 'i' flag for case-insensitive search
    return items.filter(
      (item) =>
        regex.test(item.name) ||
        (item.price && parseFloat(item.price) <= maxPrice)
    );
  };

  const handleSearchChange = (e) => {
    clearTimeout(searchTimeout);
    setSearchText(e.target.value);

    // debounce method
    setSearchTimeout(
      setTimeout(() => {
        const maxPrice = e.target.value; // You can set the desired maximum price here
        const searchResult = filterPrompts(e.target.value, maxPrice);
        setSearchedResults(searchResult);
      }, 500)
    );
  };
  const [open, setOpen] = useState(false);

  const [img, setImg] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p-4 max-w-7xl mx-auto">
      <div className="mt-3 mb-5">
        <h1 className="text-2xl font-bold ">
          {id
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .split(/(?=[A-Z])/)
            .join(" ")}
        </h1>
        {/* <p className="text-gray-500 py-2 md:text-md text-xs">
          Bakery, Desserts, Shawarma, Sandwich, Pizza, Fast Food, Salad,
          Beverages
        </p> */}
      </div>

      <form className="relative w-full flex-center ">
        <input
          type="text"
          placeholder="Search with dish name or price"
          value={searchText}
          onChange={handleSearchChange}
          required
          className="search_input "
        />
      </form>

      {open && (
        <>
          <div className="slider">
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="close"
              onClick={() => setOpen(false)}
            />

            <div className="sliderWrapper">
              <img
                src={img}
                alt=""
                className="sliderImg"
                style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  maxHeight: 500,
                }}
              />
            </div>
          </div>
        </>
      )}

      <div className=" grid grid-cols-2 md:gap-8 gap-5 md:grid-cols-3 lg:grid-cols-4 max-w-7xl mx-auto mt-8 mb-5">
        {searchedResults?.map((item, i) => {
          return (
            <div className="border-2 border-gray-300 p-3 rounded-lg md:max-w-[300px] shadow-2xl">
              <div className="flex  justify-between items-start gap-5">
                <div className="flex-1 flex-col flex justify-start items-center gap-3 cursor-pointer ">
                  <img
                    src={item.image ? item.image : Soup}
                    alt="user_image"
                    className=" w-full md:h-44 h-24  "
                    style={{
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                    onClick={() => {
                      setOpen(true);
                      setImg(item.image);
                    }}
                  />

                  <div className="px-2 flex flex-col space-y-3">
                    <h3 className="font-satoshi  md:text-xl text-sm font-extrabold text-gray-900">
                      {item.name} (A/c)
                    </h3>
                    <p className="font-satoshi text-md text-white font-extrabold text-center bg-gradient-to-r from-blue-600 to-cyan-600 rounded-md py-1">
                      Rs.{item.price}
                    </p>
                    <div className="flex md:flex-row flex-col font-thin text-sm  items-center justify-center md:space-x-2 space-x-0 md:space-y-0 space-y-1">
                      <div className="md:space-x-0 space-x-1">
                        <FontAwesomeIcon
                          icon={faStar}
                          color="green"
                          size="xs"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          color="green"
                          size="xs"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          color="green"
                          size="xs"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          color="green"
                          size="xs"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          color="green"
                          size="xs"
                        />
                      </div>
                      <p className="md:text-sm text-xs">
                        From Wifi Bawarchi ❤️
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {searchedResults?.length === 0 && (
          <p className="text-gray-500 text-md text-center">
            Oops! Nothing Found
          </p>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;
